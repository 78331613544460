import React, { useContext } from "react";

//Style
import "./PublicFormScreen.css";

//Components
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import PublicFormBody from "../../components/PublicFormBody/PublicFormBody";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const PublicFormScreen = () => {
  const { loading } = useContext(LoadingContext);

  return (
    <>
      {loading && <LoadingScreenComponent />}
      <div className={`body-container ${loading ? "no-scroll" : ""}`}>
        <div className="body-subcontainer">
          <PublicFormBody />
        </div>
      </div>
    </>
  );
};

export default PublicFormScreen;
