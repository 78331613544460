import React, { useEffect } from "react";
import { CLIENT_ID, CLIENT_SECRET } from "../../constants/backendApi";

//Style
import "./FormVerifyBody.css";

//Data
import verifyFormData from "../../json/CreateCreditData/CreateCreditData.json";

//Components
import FormWithStepsComponent from "../FormWithStepsComponent/FormWithStepsComponent";

const FormVerifyBody = () => {
  useEffect(() => {
    fetch(
      `https://crediseguro-back.click/generate-token?clientId=${CLIENT_ID}&clientSecret=${CLIENT_SECRET}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.auth) {
          localStorage.setItem("init_token", data.token);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <section className="form-verify-section">
      <div className="form-verify-subcontainer">
        <div className="fv-form-container">
          <img
            className="logo"
            src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/home/assets/logo.webp"
            alt=""
          />
          <div className="reference-value-container">
            <div className="reference-value-subcontainer">
              <img
                className="icon"
                src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/profile-icon.webp"
                alt=""
              />
              <h1 className="reference-value">Número de referencia: 69420</h1>
            </div>
          </div>
          <div className="form-with-steps-supercontainer">
            <FormWithStepsComponent
              inputData={verifyFormData.data[0]}
              hasSteps={true}
              hasRestoreBtn={false}
            />
          </div>
        </div>
        <img
          src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/bgs/woman-deco-img-3.webp"
          className="profile-deco"
          alt=""
        />
      </div>
    </section>
  );
};

export default FormVerifyBody;
