import React, { useContext, useEffect, useState } from "react";

//Style
import "./ConfirmDataBody.css";

//Contexts
import { LoadingContext } from "../../context/LoadingContext";

const ConfirmDataBody = () => {
  const [currentFee, setCurrentFee] = useState({});

  const { setLoading } = useContext(LoadingContext);

  const token = localStorage.getItem("token");

  useEffect(() => {
    try {
      setLoading(true);

      try {
        fetch("https://9d14-161-10-244-188.ngrok-free.app/api/installments", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.message === "informacion creditos") {
              const creditId = localStorage.getItem("credit_id");

              const creditData = data.data?.Creditos?.filter(
                (item) => item.Idcredito === creditId
              );

              if (creditData.length > 0) {
                const currentFeeData =
                  creditData[0]?.cuotas[0]?.cuotasPendientes[0];

                setCurrentFee(currentFeeData);
              }
            }
          })
          .finally(() => setLoading(false))
          .catch((err) => console.error(err));
      } catch (err) {
        console.error(err);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handlePay = () => {
    try {
      setLoading(true);

      const body = {
        description: `Crédito ${currentFee.CVC_Credito_Cuota__r?.Name}: ${currentFee.CVC_Credito_Cuota__r?.Cuota_a_pagar__c}`,
        installments: [currentFee],
        total: currentFee.CVC_Valor_Cuota_Pagar_Fasarela2__c,
      };

      fetch("https://9d14-161-10-244-188.ngrok-free.app/api/new-payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.message === "redirect") {
            localStorage.removeItem("credit_data");
            window.location.href = data.data?.processUrl;
          }
        })
        .finally(() => setLoading(false))
        .catch((err) => console.error(err));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section className="confirm-data-section">
      <div className="confirm-data-subcontainer">
        <div className="cd-logo-container">
          <img
            className="logo"
            src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/home/assets/logo.webp"
            alt=""
          />
          <div className="reference-value-container">
            <div className="reference-value-subcontainer">
              <img
                className="icon"
                src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/profile-icon.webp"
                alt=""
              />
              <h1 className="reference-value">Número de referencia: 69420</h1>
            </div>
          </div>
        </div>
        <div className="cd-preview-container">
          <table className="cd-preview-subcontainer">
            <tr>
              <td className="cd-preview-title underline">
                Nombre: {currentFee.CVC_Credito_Cuota__r?.CVC_Tomador__c}
              </td>
              <td className="cd-preview-title underline">Placa: 123456</td>
              <td className="cd-preview-title underline">Ramo: Auto</td>
            </tr>
            <tr>
              <td className="cd-preview-title">
                Número de documento:{" "}
                {
                  currentFee.CVC_Credito_Cuota__r?.CVC_Titular__r
                    ?.CVC_Numero_Documento__c
                }
              </td>
              <td className="cd-preview-title">
                Crédito: {currentFee.CVC_Credito_Cuota__r?.Name}
              </td>
              <td className="cd-preview-title">
                Referencia de pago: {currentFee.CVC_Referencia_de_Pago__c}
              </td>
            </tr>
          </table>
        </div>
        <div className="cd-payment-btn-container">
          <div className="cd-payment-btn-subcontainer">
            <h1 className="cd-pb-title">Paga tu primera cuota</h1>
            <h2 className="cd-pb-subtitle">
              Valor a pagar:{" "}
              {new Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(currentFee.CVC_Valor_Cuota_Pagar_Fasarela2__c)}
            </h2>
            <button className="cd-pb-btn" onClick={handlePay}>
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConfirmDataBody;
