import React, { useContext } from "react";

//Style
import "./SimulatorScreen.css";

//Data
import loginPopupData from "../../json/LoginScreen/button.json";

//Components
import NavbarComponent from "../../components/NavbarComponent/NavbarComponent";
import SideMenusComponent from "../../components/SideMenusComponent/SideMenusComponent";
import SimulatorBody from "../../components/SimulatorBody/SimulatorBody";
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import NavbarMobile from "../../components/NavbarMobile/NavbarMobile";
import NavbarMobileBottom from "../../components/NavbarMobileBotton/NavbarMobileBottom";
import PopUpComponent from "../../components/PopUpComponent/PopUpComponent";
import VersionTxtComponent from "../../components/VersionTxtComponent/VersionTxtComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";
import { PopupContext } from "../../context/PopupContext";

const SimulatorScreen = () => {
  const { loading } = useContext(LoadingContext);

  const { showPopup, popupData } = useContext(PopupContext);

  return (
    <>
      {loading && <LoadingScreenComponent />}
      {showPopup && (
        <PopUpComponent content={popupData} btnData={loginPopupData.data[0]} />
      )}
      <div className="body-container">
        <div className={`body-subcontainer ${loading ? "no-scroll" : ""}`}>
          <SimulatorBody />
        </div>
      </div>
    </>
  );
};

export default SimulatorScreen;
