import React, { useEffect } from "react";

//Style
import "./PublicFormBody.css";

//Data
import validatePolicyData from "../../json/PublicCreateCreditData/ValidatePolicyInputData.json";

//Components
import FormWithStepsComponent from "../FormWithStepsComponent/FormWithStepsComponent";

const PublicFormBody = () => {
  return (
    <section className="more-info-data-form-section">
      <div className="more-info-data-form-subcontainer">
        <img
          className="logo"
          src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/home/assets/logo.webp"
          alt=""
        />
        <div className="form-with-steps-supercontainer">
          <FormWithStepsComponent
            inputData={validatePolicyData.data[0]}
            hasSteps={false}
            hasRestoreBtn={false}
          />
        </div>
      </div>
    </section>
  );
};

export default PublicFormBody;
