import React, { useContext } from "react";

//Style
import "./ConfirmDataScreen.css";

//Components
import LoadingScreenComponent from "../../components/LoadingScreenComponent/LoadingScreenComponent";
import ConfirmDataBody from "../../components/ConfirmDataBody/ConfirmDataBody";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const ConfirmDataScreen = () => {
  const { loading } = useContext(LoadingContext);

  return (
    <>
      {loading && <LoadingScreenComponent />}
      <div className={`body-container ${loading ? "no-scroll" : ""}`}>
        <div className="body-subcontainer">
          <ConfirmDataBody />
        </div>
      </div>
    </>
  );
};

export default ConfirmDataScreen;
