import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

//Style
import "./ButtonComponent.css";

//Context
import { PopupContext } from "../../context/PopupContext";
import { LoadingContext } from "../../context/LoadingContext";

const ButtonComponent = ({ btnData, hasBlock, block }) => {
  const { setShowPopup } = useContext(PopupContext);

  const { setLoading } = useContext(LoadingContext);

  const history = useNavigate();

  const handleDeleteCreditDataFromLS = () => {
    localStorage.removeItem("latestStep");
    localStorage.removeItem("processType");
    localStorage.removeItem("isCreditInProcess");
    setShowPopup(false);
  };

  const handleCreateCredit = (body) => {
    setShowPopup(false);
    setLoading(true);

    const initToken = localStorage.getItem("init_token");

    const numDocument = body?.data?.cuenta?.numero_documento;

    try {
      fetch("https://crediseguro-back.click/createCredit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${initToken}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.idCreditoCreado !== null) {
            setTimeout(() => {
              try {
                fetch("https://9d14-161-10-244-188.ngrok-free.app/api/login", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    document: numDocument,
                    password: numDocument,
                  }),
                })
                  .then((res) => res.json())
                  .then((data) => {
                    if (
                      data?.message === "Usuario existe" ||
                      data?.message === "Usuario registrado"
                    ) {
                      localStorage.removeItem("init_token");
                      localStorage.setItem("token", data.data);
                      setLoading(false);
                      history("/confirmar-datos");
                    }
                  })
                  .catch((err) => console.error(err));
              } catch (err) {
                console.error(err);
              }
            }, 1000);

            localStorage.setItem("credit_id", data.idCreditoCreado);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {btnData.action === "close-popup" && (
        <Link
          onClick={() => setShowPopup(false)}
          className={btnData.page}
          type={btnData.type}
          title={btnData.title}
        >
          {btnData.text}
        </Link>
      )}
      {btnData.action === "submit" && (
        <button
          className={btnData.page}
          type={btnData.type}
          title={btnData.title}
          disabled={hasBlock ? block : false}
        >
          {btnData.text}
        </button>
      )}
      {btnData.action === "redirect" && (
        <Link
          className={btnData.page}
          type={btnData.type}
          title={btnData.title}
          disabled={hasBlock ? block : false}
          to={btnData.redirect}
        >
          {btnData.text}
        </Link>
      )}
      {btnData.action === "delete-data-ls" && (
        <Link
          onClick={handleDeleteCreditDataFromLS}
          className={btnData.page}
          type={btnData.type}
          title={btnData.title}
        >
          {btnData.text}
        </Link>
      )}
      {btnData.action === "create-credit" && (
        <button
          onClick={() => handleCreateCredit(btnData.data)}
          className={btnData.page}
          type={btnData.type}
          title={btnData.title}
        >
          {btnData.text}
        </button>
      )}
    </>
  );
};

export default ButtonComponent;
