import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

//Page
import PublicFormScreen from "../pages/PublicFormScreen/PublicFormScreen";
import FormVerifyScreen from "../pages/FormVerifyScreen/FormVerifyScreen";
import SimulatorScreen from "../pages/SimulatorScreen/SimulatorScreen";
import ConfirmDataScreen from "../pages/ConfirmDataScreen/ConfirmDataScreen";

const AppRouter = () => {
  return (
    <Routes>
      <Route end path="/formulario" element={<PublicFormScreen />} />
      <Route end path="/formulario-verificar" element={<FormVerifyScreen />} />
      <Route end path="/simulador-credito" element={<SimulatorScreen />} />
      <Route end path="/confirmar-datos" element={<ConfirmDataScreen />} />
      {/* {currentUser.profile !== "Aseguradora" && (
        <Route
          end
          path="/admin/crear-credito/formulario"
          element={
            <MoreInfoDataFormScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
      )} */}
      {/* 
        {currentUser.profile !== "Aseguradora" && (
          <Route
            end
            path="/admin/simulador-credito"
            element={
              <SimulatorScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )}
        {currentUser.profile !== "Aseguradora" && (
          <Route
            end
            path="/admin/crear-credito/validar-documento"
            element={
              <CreateCreditValidateDocumentScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )}
        {currentUser.profile !== "Aseguradora" && (
          <Route
            end
            path="/admin/crear-credito/adjuntar-documento"
            element={
              <AttachDocScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )}
        {currentUser.profile !== "Aseguradora" && (
          <Route
            end
            path="/admin/crear-credito/adjuntar-poliza"
            element={
              <AttachPolicyScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )}
        {currentUser.profile !== "Aseguradora" && (
          <Route
            end
            path="/admin/crear-credito/adjuntar-anexo"
            element={
              <AttachAppendixScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )}
        {currentUser.profile !== "Aseguradora" && (
          <Route
            end
            path="/admin/crear-credito/verificar-datos"
            element={
              <VerificationUserDataScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )}
        {currentUser.profile !== "Aseguradora" && (
          <Route
            end
            path="/admin/crear-credito/formulario-renovacion"
            element={
              <MoreInfoDataRenovationScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )}
  
        {currentUser.profile !== "Aseguradora" && (
          <Route
            end
            path="/admin/crear-credito/formulario-renovacion-confirmar"
            element={
              <CreateCreditRenovationScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )}
        {currentUser.profile !== "Aseguradora" && (
          <Route
            end
            path="/admin/crear-credito/formulario-confirmar"
            element={
              <CreateCreditScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )}
        <Route
          end
          path="/admin/creditos-mora"
          element={
            <CertificationScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/creditos-proceso"
          element={
            <CreditsInProcessTableScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        {currentUser.profile !== "Aseguradora" && (
          <Route
            end
            path="/admin/renovaciones-creditos"
            element={
              <RenovationTableScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )}
        <Route
          end
          path="/admin/metodos-de-pago"
          element={
            <PaymentMethodScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        {currentUser.profile !== "Aseguradora" && (
          <Route
            end
            path="/admin/crear-credito/encuesta"
            element={
              <SurveyScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )}
        <Route
          end
          path="/admin/creditos-cancelacion"
          element={
            <CancellationScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        {currentUser.profile !== "Aseguradora" && (
          <Route
            end
            path="/admin/creditos-proceso-cancelacion-avisado"
            element={
              <CancellationProcessScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )}
        {currentUser.profile !== "Aseguradora" && (
          <Route
            end
            path="/admin/certificaciones"
            element={
              <DocCertificationScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )}
        {currentUser.profile === "Aseguradora" && (
          <Route
            end
            path="/admin/creditos-vigentes"
            element={
              <CurrentCreditScreen
                userData={currentUser}
                userMenuData={currentUserMenu}
              />
            }
          />
        )} */}
      <Route path="*" element={<Navigate to="/formulario" />} />
    </Routes>
  );
};

export default AppRouter;
