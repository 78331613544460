import React, { useEffect, useReducer, useState } from "react";

//Routes
import LoginRouter from "./routes/LoginRouter";

//Context
import { LoadingContext } from "./context/LoadingContext";
import { PopupContext } from "./context/PopupContext";
import { AuthContext } from "./context/AuthContext";

//Reducer
import { AuthReducer } from "./reducers/AuthReducer";

const init = () => {
  return JSON.parse(localStorage.getItem("log")) || { log: true };
};

function App() {
  const [loading, setLoading] = useState(false);

  const [hasComplementTxt, setHasComplementTxt] = useState(false);

  const [showPopup, setShowPopup] = useState(false);

  const [popupData, setPopupData] = useState({});

  const [log, dispatch] = useReducer(AuthReducer, {}, init);

  useEffect(() => {
    localStorage.setItem("log", JSON.stringify(log));
  }, [log]);

  useEffect(() => {
    const handleWindowClose = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", handleWindowClose);

    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, []);

  useEffect(() => {
    function blockRightClick(e) {
      e.preventDefault();
    }

    document.addEventListener("contextmenu", blockRightClick);

    function blockInspectElm(e) {
      if (
        e.key === "F12" ||
        (e.ctrlKey &&
          e.shiftKey &&
          (e.key === "C" || e.key === "I" || e.key === "c" || e.key === "i")) ||
        (e.ctrlKey && (e.key === "U" || e.key === "u"))
      ) {
        console.log("Acceso no autorizado al Inspector de Elementos.");
        e.preventDefault();
      }
    }

    document.addEventListener("keydown", blockInspectElm);

    function handleDevCommands(e) {
      if (e.ctrlKey && e.altKey && e.key === "c") {
        const userInput = prompt("Ingresa un comando:");

        if (userInput === "hacker-mode") {
          document.removeEventListener("contextmenu", blockRightClick);
          console.log("Happy hacking!! :)");
        }
      }
    }

    document.addEventListener("keydown", handleDevCommands);
  }, []);

  return (
    <AuthContext.Provider value={{ log, dispatch }}>
      <LoadingContext.Provider
        value={{ loading, setLoading, hasComplementTxt, setHasComplementTxt }}
      >
        <PopupContext.Provider
          value={{ showPopup, setShowPopup, popupData, setPopupData }}
        >
          <LoginRouter />
        </PopupContext.Provider>
      </LoadingContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
